<template>
  <div>
    <!-- Header: Personal Info -->

    <!-- Form: Personal Info Form -->
    <b-form>
      <b-form>
        <div class="d-flex">
          <feather-icon icon="ProfileIcon" size="19" />
          <h4 class="mb-0">Enrollment Information</h4>
        </div>

        <!-- Form: Add Inf -->
        <b-row class="mt-1">
          <!-- Field: Attributes - Funding Source -->
          <b-col cols="12" lg="2" md="3" xs="12">
            <b-form-group label="Funding Source" label-for="profile-attr-funding-source">
              <v-select
                id="profile-attr-funding-source"
                :value="profileDataLocal.attributes.fundingSource"
                :options="fundingSourceOptions"
                class="w-100"
                :clearable="false"
                :reduce="(val) => val.value"
                @input="(val) => (profileDataLocal.attributes.fundingSource = val)"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Attributes - Payment Status -->
          <b-col v-if="false" cols="12" lg="2" md="3" xs="12">
            <b-form-group label="Payment Status" label-for="profile-attr-payment-status">
              <v-select
                id="profile-attr-payment-status"
                :value="profileDataLocal.attributes.paymentStatus"
                :options="paymentStatusOptions"
                class="w-100"
                :clearable="false"
                :reduce="(val) => val.value"
                @input="(val) => (profileDataLocal.attributes.paymentStatus = val)"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Attributes - Submitted to VA -->
          <b-col cols="12" lg="2" md="3" xs="12">
            <b-form-group label="Submitted to VA" label-for="profile-attr-submitted-to-va">
              <v-select
                id="profile-attr-submitted-to-va"
                :value="profileDataLocal.attributes.submittedToVa"
                :options="submittedToVaOptions"
                class="w-100"
                :clearable="false"
                :reduce="(val) => val.value"
                @input="(val) => (profileDataLocal.attributes.submittedToVa = val)"
              />
            </b-form-group>
          </b-col>

          <!-- Field: coe uploaded -->
          <b-col cols="12" lg="2" md="3" xs="12">
            <b-form-group label="Uploaded COE" label-for="profile-attr-u-coe">
              <v-select
                id="profile-attr-u-coe"
                :value="profileDataLocal.attributes.uploadedCoe"
                :options="uploadedCoeOptions"
                class="w-100"
                :clearable="false"
                :reduce="(val) => val.value"
                @input="(val) => (profileDataLocal.attributes.uploadedCoe = val)"
              />
            </b-form-group>
          </b-col>

          <!-- Field: transcript uploaded -->
          <b-col cols="12" lg="2" md="3" xs="12">
            <b-form-group label="Uploaded Transcript" label-for="profile-attr-u-transcript">
              <v-select
                id="profile-attr-u-resume"
                :value="profileDataLocal.attributes.uploadedTranscript"
                :options="uploadedTranscriptOptions"
                class="w-100"
                :clearable="false"
                :reduce="(val) => val.value"
                @input="(val) => (profileDataLocal.attributes.uploadedTranscript = val)"
              />
            </b-form-group>
          </b-col>

          <!-- Field: resume uploaded -->
          <b-col cols="12" lg="2" md="3" xs="12">
            <b-form-group label="Uploaded Resume" label-for="profile-attr-u-resume">
              <v-select
                id="profile-attr-u-resume"
                :value="profileDataLocal.attributes.uploadedResume"
                :options="uploadedResumeOptions"
                class="w-100"
                :clearable="false"
                :reduce="(val) => val.value"
                @input="(val) => (profileDataLocal.attributes.uploadedResume = val)"
              />
            </b-form-group>
          </b-col>

          <!-- Field: location -->
          <b-col cols="12" lg="2" md="3" xs="12">
            <b-form-group label="Location" label-for="profile-attr-u-location">
              <v-select
                id="profile-attr-u-location"
                :value="profileDataLocal.attributes.location"
                :options="locationOptions"
                class="w-100"
                :clearable="false"
                :reduce="(val) => val.value"
                @input="(val) => (profileDataLocal.attributes.location = val)"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Representative -->
          <b-col cols="12" lg="2" md="6" xs="12">
            <b-form-group label="Representative" label-for="profile-representative">
              <vue-autosuggest
                id="profile-representative"
                ref="autocomplete"
                v-model="representativeQuery"
                :suggestions="representativeSuggestions"
                :input-props="representativeInputProps"
                :section-configs="representativeSectionConfigs"
                :render-suggestion="representativeRenderSuggestion"
                :get-suggestion-value="representativeGetSuggestionValue"
                @input="representativeFetchResults"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Pathway -->
          <b-col cols="12" lg="3" md="6" xs="12">
            <b-form-group label="Preferred Pathway" label-for="profile-pathway">
              <v-select
                id="profile-pathway"
                placeholder="Preferred Pathway"
                :value="profileDataLocal.preferred.pathway"
                :options="pathwaySuggestions"
                class="w-100"
                :reduce="(val) => val.value"
                :clearable="true"
                @search="searchForPathway"
                @input="(val) => (profileDataLocal.preferred.pathway = val)"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Course -->
          <b-col cols="12" lg="3" md="6" xs="12">
            <b-form-group label="Preferred Course" label-for="profile-course">
              <v-select
                id="profile-course"
                placeholder="Preferred Course"
                :value="profileDataLocal.preferred.course"
                :options="courseSuggestions"
                class="w-100"
                :reduce="(val) => val.value"
                :clearable="true"
                @search="searchForCourse"
                @input="(val) => (profileDataLocal.preferred.course = val)"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Pathway Date -->
          <b-col cols="12" lg="2" md="6" xs="12">
            <b-form-group label="Preferred Start Date" label-for="preferredDate">
              <b-form-datepicker id="preferredDate" v-model="profileDataLocal.preferred.startDate" class="mb-1" />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <!-- Field: Status -->
          <b-col cols="12" lg="2" md="6" xs="12">
            <b-form-group label="Status" label-for="profile-status">
              <v-select
                id="profile-status"
                :value="profileDataLocal.status"
                :options="statusOptions"
                class="w-100"
                :clearable="false"
                :reduce="(val) => val.value"
                @input="(val) => (profileDataLocal.status = val)"
              />
            </b-form-group>

            <!-- Field: Profile Status Update Date -->
            <template v-if="profileData && profileDataLocal && profileData.status !== profileDataLocal.status">
              <b-form-group label="Propagate" label-for="propagation-mode">
                <v-select
                  id="propagation-mode"
                  v-model="propagation.mode"
                  :options="propagationOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  class="d-inline-block"
                />
              </b-form-group>
              <b-form-group v-if="'future' === propagation.mode" label="Effective As Of" label-for="propagation-effectiveAt">
                <b-form-datepicker id="propagation-effectiveAt" v-model="propagation.effectiveAt" />
              </b-form-group>
            </template>
          </b-col>

          <!-- Field: Profile Status Reason -->
          <b-col v-if="shouldProvideReason" cols="12" lg="5" md="6" xs="12">
            <b-form-group label="Status Reason" label-for="profile-status-reason">
              <b-form-input id="profile-status-reason" v-model="profileDataLocal.statusReason" class="w-100" />
            </b-form-group>
          </b-col>

          <!-- Field: Profile Status Update Date -->
          <b-col v-if="shouldProvideReason" cols="12" lg="3" md="6" xs="12">
            <b-form-group label="Status Date" label-for="profile-status-date">
              <b-form-datepicker id="profile-status-date" v-model="profileDataLocal.statusDate" class="mb-1" />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="showReassignSection">
          <b-col cols="12">
            <h4 class="mb-0">Leave of Absence</h4>
          </b-col>

          <!-- Field: Profile Reassign Reason -->
          <b-col cols="12" :lg="lastOptionsSelected === 'reassignReason' ? 12 : 3" md="12" xs="12">
            <b-form-group label="Reassign Reason" label-for="profile-status-reason">
              <b-form-input
                @focusin="lastOptionsSelected = 'reassignReason'"
                @focusout="lastOptionsSelected = undefined"
                id="profile-reassign-reason"
                v-model="profileDataLocal.reassignReason"
                class="w-100"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Reassign From -->
          <b-col cols="12" lg="3" md="6" xs="12">
            <b-form-group label="Reassign From" label-for="profile-reassignFrom">
              <v-select
                id="profile-reassignFrom"
                placeholder="Reassign From"
                :value="profileDataLocal.reassignFrom"
                :options="cohortSuggestions"
                class="w-100"
                :reduce="(val) => val.value"
                :clearable="true"
                @search="searchForCohort"
                @input="(val) => (profileDataLocal.reassignFrom = val)"
              />
            </b-form-group>
          </b-col>

          <!-- Field: LOA Start Date -->
          <b-col cols="12" lg="3" md="6" xs="12">
            <b-form-group label="LoA Start Date" label-for="loa-start-date">
              <b-form-datepicker id="loa-start-date" v-model="profileDataLocal.reassignStart" class="mb-1" />
            </b-form-group>
          </b-col>

          <!-- Field: LOA End Date -->
          <b-col cols="12" lg="3" md="6" xs="12">
            <b-form-group label="LoA End Date" label-for="loa-start-date">
              <b-form-datepicker id="loa-end-date" v-model="profileDataLocal.reassignEnd" class="mb-1" />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="showTransferCreditSection">
          <b-col cols="12">
            <h4 class="mb-0">Transfer of Credit</h4>
          </b-col>

          <b-col cols="12" lg="12" md="12" xs="12">
            <b-form-group label="Reason / Notes" label-for="profile-section-toc-note">
              <b-form-input
                id="profile-section-toc-note"
                :value="profileDataLocal.sections[this.sectionId].toc.notes"
                @input="(val) => (profileDataLocal.sections[this.sectionId].toc.notes = val)"
                class="w-100"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <div class="d-flex" v-if="enableSectionAttributes">
          <feather-icon icon="ProfileIcon" size="19" />
          <h4 class="mb-0">Section Information</h4>
        </div>

        <b-row class="mt-1" v-if="enableSectionAttributes">
          <!-- Field: Exam Voucher -->
          <b-col cols="12" lg="2" md="2" xs="12">
            <b-form-group label="Section Exam Voucher" label-for="profile-section-exam-voucher">
              <v-select
                id="profile-section-exam-voucher"
                :value="profileDataLocal.sections[this.sectionId].examVoucher"
                :options="examVoucherOptions"
                class="w-100"
                :clearable="false"
                :reduce="(val) => val.value"
                @input="(val) => (profileDataLocal.sections[this.sectionId].examVoucher = val)"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Exam Taken -->
          <b-col cols="12" lg="2" md="2" xs="12">
            <b-form-group label="Section Exam Taken" label-for="profile-section-exam-taken">
              <v-select
                id="profile-section-exam-taken"
                :value="profileDataLocal.sections[this.sectionId].examTaken"
                :options="yesNoOptions"
                class="w-100"
                :clearable="false"
                :reduce="(val) => val.value"
                @input="(val) => (profileDataLocal.sections[this.sectionId].examTaken = val)"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Exam Passed -->
          <b-col cols="12" lg="2" md="2" xs="12">
            <b-form-group label="Section Exam Passed" label-for="profile-section-exam">
              <v-select
                id="profile-section-exam"
                :value="profileDataLocal.sections[this.sectionId].examPassed"
                :options="yesNoOptions"
                class="w-100"
                :clearable="false"
                :reduce="(val) => val.value"
                @input="(val) => (profileDataLocal.sections[this.sectionId].examPassed = val)"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Lab Completed -->
          <b-col cols="12" lg="2" md="6" xs="12">
            <b-form-group label="Section Lab Completed" label-for="profile-section-lab">
              <v-select
                id="profile-section-lab"
                :value="profileDataLocal.sections[this.sectionId].labCompleted"
                :options="yesNoOptions"
                class="w-100"
                :clearable="false"
                :reduce="(val) => val.value"
                @input="(val) => (profileDataLocal.sections[this.sectionId].labCompleted = val)"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Status -->
          <b-col cols="12" lg="3" md="3" xs="12">
            <b-form-group label="Section Status" label-for="profile-section-status">
              <v-select
                id="profile-section-status"
                :value="profileDataLocal.sections[this.sectionId].status"
                :options="sectionStatusOptions"
                class="w-100"
                :clearable="false"
                :reduce="(val) => val.value"
                @input="(val) => (profileDataLocal.sections[this.sectionId].status = val)"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Voucher Purchased -->
          <b-col cols="12" lg="2" md="6" xs="12">
            <b-form-group label="Voucher Purchased" label-for="profile-section-voucher-purchased">
              <v-select
                id="profile-section-voucher-purchased"
                :value="profileDataLocal.sections[this.sectionId].voucherPurchased"
                :options="yesNoOptions"
                class="w-100"
                :clearable="false"
                :reduce="(val) => val.value"
                @input="(val) => (profileDataLocal.sections[this.sectionId].voucherPurchased = val)"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Voucher Issued -->
          <b-col cols="12" lg="2" md="6" xs="12">
            <b-form-group label="Voucher Issued" label-for="profile-section-voucher-issued">
              <v-select
                id="profile-section-voucher-issued"
                :value="profileDataLocal.sections[this.sectionId].voucherIssued"
                :options="yesNoOptions"
                class="w-100"
                :clearable="false"
                :reduce="(val) => val.value"
                @input="(val) => (profileDataLocal.sections[this.sectionId].voucherIssued = val)"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Voucher Code -->
          <b-col cols="12" lg="4" md="6" xs="12">
            <b-form-group label="Voucher Code" label-for="profile-section-voucher-code">
              <b-form-input
                id="profile-section-voucher-code"
                :value="profileDataLocal.sections[this.sectionId].voucherCode"
                @input="(val) => (profileDataLocal.sections[this.sectionId].voucherCode = val)"
                class="w-100"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Voucher Issued Date -->
          <b-col cols="12" lg="2" md="6" xs="12">
            <b-form-group label="Voucher Issued Date" label-for="profile-section-voucher-issued-date">
              <b-form-datepicker
                id="profile-section-voucher-issued-date"
                :value="profileDataLocal.sections[this.sectionId].voucherIssuedDate"
                @input="(val) => (profileDataLocal.sections[this.sectionId].voucherIssuedDate = val)"
                class="mb-1"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Voucher Expiration Date -->
          <b-col cols="12" lg="2" md="6" xs="12">
            <b-form-group label="Voucher Expiration Date" label-for="profile-section-voucher-expiration-date">
              <b-form-datepicker
                id="profile-section-voucher-expiration-date"
                :value="profileDataLocal.sections[this.sectionId].voucherExpirationDate"
                @input="(val) => (profileDataLocal.sections[this.sectionId].voucherExpirationDate = val)"
                class="mb-1"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>

      <b-row class="mt-2 w-100">
        <b-col cols="12" lg="12">
          <b-button variant="primary" class="float-right" :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="doSaveChanges">
            Save Changes
          </b-button>
          <b-button
            variant="outline-secondary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="$router.back()"
          >
            <feather-icon icon="ArrowLeftIcon" />
            Return
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BFormCheckbox,
  BFormDatepicker,
} from 'bootstrap-vue';

import Cleave from 'vue-cleave-component';
import flatPickr from 'vue-flatpickr-component';
import { onUnmounted, ref } from '@vue/composition-api';
import vSelect from 'vue-select';
import store from '@/store';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import useProfiles from '../../useProfiles';
import rosterStoreModule from '../../../../../../store/roster/rosterStoreModule';
import userStoreModule from '../../../../../../store/user/userStoreModule';
import { VueAutosuggest } from 'vue-autosuggest';

export default {
  components: {
    Cleave,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    BInputGroup,
    vSelect,
    BFormRadioGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormDatepicker,
    BButton,
    ToastificationContent,
    VueAutosuggest,
  },
  props: {
    profileData: {
      type: Object,
      required: true,
    },
  },
  watch: {
    profileData() {},
  },
  computed: {
    shouldProvideReason() {
      return (
        this.profileDataLocal.statusReason ||
        ['withdrew_s1', 'withdrew_s2', 'withdrew_s3', 'terminated'].includes(this.profileDataLocal.status)
      );
    },

    showReassignSection() {
      return (
        this.profileDataLocal.status === 'leave_of_absence' ||
        this.profileDataLocal.reassignAt ||
        this.profileDataLocal.reassignReason ||
        this.profileDataLocal.reassignStart ||
        this.profileDataLocal.reassignEnd ||
        this.profileDataLocal.reassignFrom
      );
    },

    showTransferCreditSection() {
      return this.sectionId && this.profileDataLocal.sections[this.sectionId].toc.enabled; //this.profileDataLocal.toc && this.profileDataLocal.toc.enabled &&
    },
  },
  data: () => {
    return {
      submit: {
        sync: false,
      },
      attributes: {
        relocatable: 'no',
        remote: 'no',
        clearance: 'none',
        location: 'virtual',
        veteran: true,
      },
      profileDataLocal: { preferred: {}, attributes: {} },

      sectionId: undefined,
      enableSectionAttributes: false,

      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },

      lastOptionsSelected: '',
      propagation: { mode: 'current_future', effectiveAt: null },

      propagationOptions: [
        { label: 'None', value: 'none' },
        { label: 'Current', value: 'current' },
        { label: 'Current & Future', value: 'current_future' },
        { label: 'Future', value: 'future' },
        { label: 'All', value: 'all' },
      ],
    };
  },
  methods: {
    async doSelectRepresentative() {
      if (!this.representativeSelected) {
        return;
      }

      this.$store
        .dispatch('placementStoreModule/addStudentsToCohort', {
          cohortId: this.cohortData.id,
          studentIds: [this.representativeSelected.id],
        })
        .then((response) => {
          console.log('updated section', response);

          console.log(response.data);

          this.$emit('refresh');

          this.representativeQuery = undefined;
          this.representativeFetchResults();
        })
        .catch((e) => {
          console.error('failed to update section', e);
        });
    },
    representativeFetchResults() {
      const { query } = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        const usersPromise = this.$store.dispatch('userStoreModule/fetchUsers', {
          search: this.representativeQuery,
        });

        Promise.all([usersPromise]).then((values) => {
          this.representativeSuggestions = [];

          const users = values[0].data.results;

          users.length && this.representativeSuggestions.push({ name: 'users', data: users });
        });
      }, 250);
    },
    representativeRenderSuggestion(row) {
      return `${row.item.name}`;
    },
    representativeGetSuggestionValue(row) {
      if (row.item) {
        return this.representativeRenderSuggestion(row);
      }
      return `${row.name}`;
    },

    cohortRenderSuggestion(suggestion) {
      return suggestion.item.name;
    },
    cohortGetSuggestionValue(suggestion) {
      const { name, item } = suggestion;
      return item.name;
    },
    async searchForCohort(search, loading) {
      const filter = { search, limit: 100, sortBy: 'createdAt:desc' };

      if (this.profileDataLocal.pathway) {
        filter.pathway = this.profileDataLocal.pathway;
      } else if (this.profileDataLocal.course) {
        filter.course = this.profileDataLocal.pathway;
      }

      const response = await this.$store.dispatch('rosterStoreModule/fetchPathwayCohortList', filter);
      const cohorts = response.data.results;

      this.cohortSuggestions = cohorts.map((p) => {
        const { id, name } = p;
        return { value: id, label: name };
      });
    },
    pathwayRenderSuggestion(suggestion) {
      return suggestion.item.name;
    },
    pathwayGetSuggestionValue(suggestion) {
      const { name, item } = suggestion;
      return item.name;
    },
    async searchForPathway(search, loading) {
      const response = await this.$store.dispatch('rosterStoreModule/fetchPathwayList', { search, limit: 100 });
      const pathways = response.data.results;

      this.pathwaySuggestions = pathways.map((p) => {
        let { id, name } = p;
        if (!name.includes('Pathway')) {
          name += ' Pathway';
        }
        return { value: id, label: name };
      });
    },
    courseRenderSuggestion(suggestion) {
      return suggestion.item.name;
    },
    courseGetSuggestionValue(suggestion) {
      const { name, item } = suggestion;
      return item.name;
    },
    async searchForCourse(search, loading) {
      const response = await this.$store.dispatch('rosterStoreModule/fetchCourseSectionList', { search, limit: 100 });
      const courses = response.data.results;

      this.courseSuggestions = courses.map((p) => {
        const { id, name } = p;
        return { value: id, label: name };
      });
    },

    doSaveChanges() {
      const id = this.profileData.id;

      const pl = {
        attributes: this.profileDataLocal.attributes,
        status: this.profileDataLocal.status,
        statusReason: this.profileDataLocal.statusReason,
        statusDate: this.profileDataLocal.statusDate,
        preferred: this.profileDataLocal.preferred,
        representative: this.profileDataLocal.representative,
        sections: this.profileDataLocal.sections,
        reassignReason: this.profileDataLocal.reassignReason,
        reassignStart: this.profileDataLocal.reassignStart,
        reassignEnd: this.profileDataLocal.reassignEnd,
        reassignFrom: this.profileDataLocal.reassignFrom,

        options: { propagation: this.propagation },
      };

      if (this.representativeSelected) {
        pl.representative = this.representativeSelected.id;
      }

      store
        .dispatch('rosterStoreModule/updateProfile', { id, profileData: pl })
        .then((response) => {
          this.profileDataLocal = response.data;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Profile Edited!',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to edit profile',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },
    loadProfile() {
      this.profileDataLocal = { ...this.profileData };
      this.searchForPathway('');
      this.searchForCourse('');

      if (this.profileDataLocal.reassignFrom) {
        this.searchForCohort('');
      }

      this.representativeSelected = this.profileData.representative;
      if (this.representativeSelected) {
        this.representativeQuery = this.representativeSelected.name;
      }

      // store.dispatch('placementStoreModule/fetchProfile', {id: this.profileDataInfo.id})
      //     .then(response => {
      //
      //       this.profileDataLocal = respoinse;
      //
      //       if (this.profileData.attributes) {
      //         this.attributes = this.profileData.attributes;
      //       }
      //
      //     });
    },
  },
  mounted() {
    this.loadProfile();

    const { section } = this.$route.query;

    if (section) {
      this.sectionId = section;

      if (!this.profileDataLocal.sections || !this.profileDataLocal.sections[section]) {
        this.profileDataLocal.sections = {};
        this.profileDataLocal.sections[section] = {
          examVoucher: 'no',
          examTaken: 'no',
          examPassed: 'no',
          labCompleted: 'no',
          status: 'pending',
        };
      }

      this.enableSectionAttributes = true;
    }
  },
  setup(props) {
    const profileDataInfo = ref(props.profileData);

    const representativeQuery = ref('');
    const representativeSuggestions = ref([]);
    const representativeSelected = ref({});
    const representativeSectionConfigs = {
      users: {
        limit: 10,
        label: 'Users',
        onSelected: (selected) => {
          representativeSelected.value = selected.item;
        },
      },
    };

    const representativeInputProps = {
      id: 'autosuggest__input_ajax',
      placeholder: 'Search for users',
      class: 'form-control',
      name: 'ajax',
    };

    const {
      statusOptions,
      clearanceOptions,
      relocatableOptions,
      remoteOptions,
      fundingSourceOptions,
      paymentStatusOptions,
      submittedToVaOptions,
      uploadedCoeOptions,
      uploadedResumeOptions,
      uploadedTranscriptOptions,
      sectionStatusOptions,
      examVoucherOptions,
      yesNoOptions,
      locationOptions,
    } = useProfiles();

    const cohortQuery = ref('');
    const cohortSuggestions = ref([]);
    const cohortSelected = ref(undefined);
    const cohortSectionConfigs = {
      cohorts: {
        limit: 10,
        label: 'Cohorts',
        onSelected: (selected) => {
          cohortSelected.value = selected.item;
          profileData.value.cohort = selected.item;
        },
      },
    };

    const cohortInputProps = {
      id: 'autosuggest__input_ajax',
      placeholder: 'Search for cohorts',
      class: 'form-control',
      name: 'ajax',
    };

    const pathwayQuery = ref('');
    const pathwaySuggestions = ref([]);
    const pathwaySelected = ref(undefined);
    const pathwaySectionConfigs = {
      pathways: {
        limit: 10,
        label: 'Pathways',
        onSelected: (selected) => {
          pathwaySelected.value = selected.item;
          profileData.value.pathway = selected.item;
        },
      },
    };

    const pathwayInputProps = {
      id: 'autosuggest__input_ajax',
      placeholder: 'Search for pathways',
      class: 'form-control',
      name: 'ajax',
    };

    const courseQuery = ref('');
    const courseSuggestions = ref([]);
    const courseSelected = ref(undefined);
    const courseSectionConfigs = {
      courses: {
        limit: 10,
        label: 'Courses',
        onSelected: (selected) => {
          courseSelected.value = selected.item;
          profileData.value.course = selected.item;
        },
      },
    };

    const courseInputProps = {
      id: 'autosuggest__input_ajax',
      placeholder: 'Search for courses',
      class: 'form-control',
      name: 'ajax',
    };

    return {
      representativeQuery,
      representativeSuggestions,
      representativeSelected,
      representativeSectionConfigs,
      representativeInputProps,

      profileDataInfo,
      statusOptions,
      clearanceOptions,
      relocatableOptions,
      remoteOptions,
      fundingSourceOptions,
      paymentStatusOptions,
      submittedToVaOptions,
      uploadedCoeOptions,
      uploadedResumeOptions,
      uploadedTranscriptOptions,
      examVoucherOptions,
      yesNoOptions,
      sectionStatusOptions,
      locationOptions,

      //
      cohortQuery,
      cohortSuggestions,
      cohortSectionConfigs,
      cohortInputProps,
      cohortSelected,
      pathwayQuery,
      pathwaySuggestions,
      pathwaySectionConfigs,
      pathwayInputProps,
      pathwaySelected,
      courseQuery,
      courseSuggestions,
      courseSectionConfigs,
      courseInputProps,
      courseSelected,
    };
  },
};
</script>

<style lang="scss"></style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';

pre {
  min-height: 295px;
  padding: 1.5rem;
  margin-bottom: 0;
  border-radius: 0.5rem;
}

.attr-tag {
  float: right;
  margin: 2.5px 0 2.5px 5px;
}
</style>
