<template>
  <div class="container content-p">
    <component :is="profileData === undefined ? 'div' : 'b-card'">
      <!-- Alert: No item found -->
      <b-alert variant="danger" :show="profileData === undefined">
        <h4 class="alert-heading">Error fetching profile data</h4>
        <div class="alert-body">
          No profile found with this profile id. Check
          <b-link class="alert-link" :to="{ name: 'apps-roster-list-pending' }"> Profile List </b-link>
          for other profiles.
        </div>
      </b-alert>

      <component :is="'div'" v-if="profileData">
        <h3>
          {{ profileData.student ? profileData.student.fullName : 'unknown' }}
          <small>{{ profileData.student ? profileData.student.myIpId : 'unknown' }}</small>
        </h3>

        <hr />

        <b-tabs pills lazy>
          <!-- Tab: Information -->
          <b-tab>
            <template #title>
              <feather-icon icon="PaperclipIcon" size="16" class="mr-0 mr-sm-50" />
              <span class="d-none d-sm-inline">Attributes</span>
            </template>
            <profile-edit-tab-attributes :profile-data.sync="profileData" class="mt-2 pt-75" />
            <!-- <profile-edit-modal :edit-profile-active="true" :profile-data="profileData" /> -->
          </b-tab>
        </b-tabs>
      </component>
    </component>
  </div>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink, BCol } from 'bootstrap-vue';
import { ref, onUnmounted } from '@vue/composition-api';
import router from '@/router';
import store from '@/store';
import rosterStoreModule from '../../../../../store/roster/rosterStoreModule';
import ProfileEditTabAttributes from './tabs/ProfileEditTabAttributes.vue';
import ProfileEditModal from './ProfileEditModal.vue';

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BCol,
    ProfileEditModal,

    ProfileEditTabAttributes,
  },
  setup() {
    const profileData = ref(null);

    store
      .dispatch('rosterStoreModule/fetchProfile', {
        id: router.currentRoute.params.id,
        queryParams: {
          populate: 'representative student',
        },
      })
      .then((response) => {
        profileData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          profileData.value = undefined;
        }
      });

    return {
      profileData,
    };
  },
};
</script>

<style></style>
